.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0, .btn, body,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-25 {
  margin: 0.25rem !important;
}

.mt-25,
.my-25 {
  margin-top: 0.25rem !important;
}

.mr-25,
.mx-25 {
  margin-right: 0.25rem !important;
}

.mb-25,
.my-25 {
  margin-bottom: 0.25rem !important;
}

.ml-25,
.mx-25 {
  margin-left: 0.25rem !important;
}

.m-50 {
  margin: 0.5rem !important;
}

.mt-50, table.table-border-top,
.my-50 {
  margin-top: 0.5rem !important;
}

.mr-50,
.mx-50 {
  margin-right: 0.5rem !important;
}

.mb-50, .nav-primary-container,
.my-50 {
  margin-bottom: 0.5rem !important;
}

.ml-50,
.mx-50 {
  margin-left: 0.5rem !important;
}

.m-75 {
  margin: 0.75rem !important;
}

.mt-75,
.my-75 {
  margin-top: 0.75rem !important;
}

.mr-75,
.mx-75 {
  margin-right: 0.75rem !important;
}

.mb-75, footer h5,
.my-75 {
  margin-bottom: 0.75rem !important;
}

.ml-75,
.mx-75 {
  margin-left: 0.75rem !important;
}

.m-100 {
  margin: 1rem !important;
}

.mt-100,
.my-100 {
  margin-top: 1rem !important;
}

.mr-100,
.mx-100 {
  margin-right: 1rem !important;
}

.mb-100,
.my-100 {
  margin-bottom: 1rem !important;
}

.ml-100,
.mx-100 {
  margin-left: 1rem !important;
}

.m-125 {
  margin: 1.25rem !important;
}

.mt-125,
.my-125 {
  margin-top: 1.25rem !important;
}

.mr-125,
.mx-125 {
  margin-right: 1.25rem !important;
}

.mb-125,
.my-125 {
  margin-bottom: 1.25rem !important;
}

.ml-125,
.mx-125 {
  margin-left: 1.25rem !important;
}

.m-150 {
  margin: 1.5rem !important;
}

.mt-150,
.my-150 {
  margin-top: 1.5rem !important;
}

.mr-150,
.mx-150 {
  margin-right: 1.5rem !important;
}

.mb-150,
.my-150 {
  margin-bottom: 1.5rem !important;
}

.ml-150,
.mx-150 {
  margin-left: 1.5rem !important;
}

.m-200 {
  margin: 2rem !important;
}

.mt-200,
.my-200 {
  margin-top: 2rem !important;
}

.mr-200,
.mx-200 {
  margin-right: 2rem !important;
}

.mb-200,
.my-200 {
  margin-bottom: 2rem !important;
}

.ml-200,
.mx-200 {
  margin-left: 2rem !important;
}

.m-300 {
  margin: 3rem !important;
}

.mt-300,
.my-300 {
  margin-top: 3rem !important;
}

.mr-300,
.mx-300 {
  margin-right: 3rem !important;
}

.mb-300,
.my-300 {
  margin-bottom: 3rem !important;
}

.ml-300,
.mx-300 {
  margin-left: 3rem !important;
}

.m-400 {
  margin: 4rem !important;
}

.mt-400,
.my-400 {
  margin-top: 4rem !important;
}

.mr-400,
.mx-400 {
  margin-right: 4rem !important;
}

.mb-400,
.my-400 {
  margin-bottom: 4rem !important;
}

.ml-400,
.mx-400 {
  margin-left: 4rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-25 {
  padding: 0.25rem !important;
}

.pt-25,
.py-25,
footer .footer-legal div,
footer .footer-content > div:not(:last-of-type) div {
  padding-top: 0.25rem !important;
}

.pr-25,
.px-25 {
  padding-right: 0.25rem !important;
}

.pb-25,
.py-25,
footer .footer-legal div,
footer .footer-content > div:not(:last-of-type) div {
  padding-bottom: 0.25rem !important;
}

.pl-25,
.px-25 {
  padding-left: 0.25rem !important;
}

.p-50 {
  padding: 0.5rem !important;
}

.pt-50, .nav-secondary-container, .nav-primary-container,
.py-50,
footer .footer-legal,
footer .footer-content {
  padding-top: 0.5rem !important;
}

.pr-50,
.px-50 {
  padding-right: 0.5rem !important;
}

.pb-50,
.py-50,
footer .footer-legal,
footer .footer-content {
  padding-bottom: 0.5rem !important;
}

.pl-50,
.px-50 {
  padding-left: 0.5rem !important;
}

.p-75, .nav-secondary-container .nav-secondary ul li a, .nav-secondary-container .nav-secondary ul li .nav-tabs button, .nav-tabs .nav-secondary-container .nav-secondary ul li button, .nav-primary-container .nav-primary ul li a, .nav-primary-container .nav-primary ul li .nav-tabs button, .nav-tabs .nav-primary-container .nav-primary ul li button {
  padding: 0.75rem !important;
}

.pt-75,
.py-75,
footer .footer-content > div:not(:last-of-type),
footer h5,
table td {
  padding-top: 0.75rem !important;
}

.pr-75,
.px-75 {
  padding-right: 0.75rem !important;
}

.pb-75,
.py-75,
footer .footer-content > div:not(:last-of-type),
footer h5,
table td {
  padding-bottom: 0.75rem !important;
}

.pl-75,
.px-75 {
  padding-left: 0.75rem !important;
}

.p-100 {
  padding: 1rem !important;
}

.pt-100, footer .footer-legal div.copyright::before,
.py-100 {
  padding-top: 1rem !important;
}

.pr-100,
.px-100 {
  padding-right: 1rem !important;
}

.pb-100,
.py-100 {
  padding-bottom: 1rem !important;
}

.pl-100,
.px-100 {
  padding-left: 1rem !important;
}

.p-125 {
  padding: 1.25rem !important;
}

.pt-125,
.py-125 {
  padding-top: 1.25rem !important;
}

.pr-125,
.px-125 {
  padding-right: 1.25rem !important;
}

.pb-125,
.py-125 {
  padding-bottom: 1.25rem !important;
}

.pl-125,
.px-125 {
  padding-left: 1.25rem !important;
}

.p-150 {
  padding: 1.5rem !important;
}

.pt-150,
.py-150 {
  padding-top: 1.5rem !important;
}

.pr-150,
.px-150 {
  padding-right: 1.5rem !important;
}

.pb-150,
.py-150 {
  padding-bottom: 1.5rem !important;
}

.pl-150,
.px-150 {
  padding-left: 1.5rem !important;
}

.p-200 {
  padding: 2rem !important;
}

.pt-200,
.py-200 {
  padding-top: 2rem !important;
}

.pr-200,
.px-200 {
  padding-right: 2rem !important;
}

.pb-200,
.py-200 {
  padding-bottom: 2rem !important;
}

.pl-200,
.px-200 {
  padding-left: 2rem !important;
}

.p-300 {
  padding: 3rem !important;
}

.pt-300,
.py-300 {
  padding-top: 3rem !important;
}

.pr-300,
.px-300 {
  padding-right: 3rem !important;
}

.pb-300,
.py-300 {
  padding-bottom: 3rem !important;
}

.pl-300,
.px-300 {
  padding-left: 3rem !important;
}

.p-400 {
  padding: 4rem !important;
}

.pt-400,
.py-400 {
  padding-top: 4rem !important;
}

.pr-400,
.px-400 {
  padding-right: 4rem !important;
}

.pb-400,
.py-400 {
  padding-bottom: 4rem !important;
}

.pl-400,
.px-400 {
  padding-left: 4rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto,
.nav-secondary-container .nav-secondary,
.nav-primary-container .nav-primary {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto,
.nav-secondary-container .nav-secondary,
.nav-primary-container .nav-primary {
  margin-left: auto !important;
}

.d-inline-block {
  display: inline-block;
}

.d-flex, .card .card-footer-actions,
.card .card-footer, .card .card-body-kpi {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
  }
}

@supports (position: sticky) {
  .sticky-bottom-right {
    position: sticky;
    bottom: 0;
    text-align: right;
  }
}

.flex-grow-1 {
  flex-grow: 1;
}

.align-items-center, .card .card-footer-actions,
.card .card-footer {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a,
.nav-tabs button, abbr, address, button, cite, code,
del, dfn, em, img, input, ins, kbd, q, s, samp, select,
small, strong, sub, sup, var,
b, u, i, dl, dt, dd, ol, optgroup, ul, li,
fieldset, form, label, legend,
table, textarea, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  height: auto;
}

otcx-layout-header {
  margin-left: calc(100vw - 100%);
}

otcx-layout-disclaimer .disclaimer-container,
footer, section {
  padding-left: calc(100vw - 100%);
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a, .nav-tabs button, button, input, textarea {
  outline: none;
}

@font-face {
  font-family: "Spezia";
  src: url("/fonts/SpeziaCompleteVariableUprightWeb.woff2") format("woff2"), url("/fonts/SpeziaCompleteVariableUprightWeb.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  unicode-range: U+000D-FB04;
}
@font-face {
  font-family: "Spezia";
  src: url("/fonts/SpeziaCompleteVariableItalicWeb.woff2") format("woff2"), url("/fonts/SpeziaCompleteVariableItalicWeb.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  unicode-range: U+000D-FB04;
}
@font-face {
  font-family: "bekb-icon-font";
  src: url("/fonts/bekb-icon-font.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.library-bekb-icon-font {
  font-family: "bekb-icon-font", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-stretch: 100%;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-adressen:before {
  content: "\e90f";
}

.icon-bearbeiten:before {
  content: "\e910";
}

.icon-burger:before {
  content: "\e901";
}

.icon-chat:before {
  content: "\e91e";
}

.icon-drucker:before {
  content: "\e911";
}

.icon-ebanking:before {
  content: "\e912";
}

.icon-einstellungen:before {
  content: "\e915";
}

.icon-email:before {
  content: "\e916";
}

.icon-facebook:before {
  content: "\e920";
}

.icon-filter:before {
  content: "\e918";
}

.icon-fullscreen:before {
  content: "\e92c";
}

.icon-home:before {
  content: "\e900";
}

.icon-informationen:before {
  content: "\e91b";
}

.icon-kalender:before {
  content: "\e919";
}

.icon-lautstaerke:before {
  content: "\e928";
}

.icon-link-extern:before {
  content: "\e91c";
}

.icon-link-intern:before {
  content: "\e905";
}

.icon-link-intern-zurueck:before {
  content: "\e906";
}

.icon-liste:before {
  content: "\e929";
}

.icon-login:before {
  content: "\e913";
}

.icon-logout:before {
  content: "\e914";
}

.icon-lupe:before {
  content: "\e90d";
}

.icon-mehr:before {
  content: "\e903";
}

.icon-neues-fenster:before {
  content: "\e91d";
}

.icon-ok:before {
  content: "\e90c";
}

.icon-paly:before {
  content: "\e922";
}

.icon-pause:before {
  content: "\e925";
}

.icon-personendaten:before {
  content: "\e90e";
}

.icon-pfeil-links:before {
  content: "\e90a";
}

.icon-pfeil-oben:before {
  content: "\e909";
}

.icon-pfeil-rechts:before {
  content: "\e90b";
}

.icon-pfeil-unten:before {
  content: "\e908";
}

.icon-reload:before {
  content: "\e92a";
}

.icon-schliessen:before {
  content: "\e902";
}

.icon-schnellruecklauf:before {
  content: "\e926";
}

.icon-schnellvorlauf:before {
  content: "\e927";
}

.icon-share:before {
  content: "\e91f";
}

.icon-shuffle:before {
  content: "\e92b";
}

.icon-telefon:before {
  content: "\e917";
}

.icon-twitter:before {
  content: "\e921";
}

.icon-upload:before {
  content: "\e907";
}

.icon-weiter:before {
  content: "\e923";
}

.icon-weniger:before {
  content: "\e904";
}

.icon-zeit:before {
  content: "\e91a";
}

.icon-zurueck:before {
  content: "\e924";
}

footer .footer-content, .nav-secondary-container .nav-secondary, .nav-primary-container .nav-primary, header {
  min-width: 290px;
  margin-right: 0.9375rem;
  margin-left: 0.9375rem;
}
@media (min-width: 768px) {
  footer .footer-content, .nav-secondary-container .nav-secondary, .nav-primary-container .nav-primary, header {
    max-width: 729.6px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 1200px) {
  footer .footer-content, .nav-secondary-container .nav-secondary, .nav-primary-container .nav-primary, header {
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
  }
}

.col-start-1 {
  grid-column-start: 1;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-end-8 {
  grid-column-end: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-end-10 {
  grid-column-end: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-end-11 {
  grid-column-end: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-end-12 {
  grid-column-end: 12;
}

html {
  font-size: 16px;
}

body {
  font-family: "Spezia", "Arial", "sans-serif";
  font-stretch: 40%;
  color: #000000;
}
body.modal-open {
  overflow: hidden;
}

otcx-root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

section[role=main] {
  width: 100%;
  flex: 1 0 auto;
}

.hero {
  margin-left: calc(-0.5 * (100vw - 100%));
  padding-left: calc(0.5 * (100vw - 100%));
  padding-top: 20vw;
  background-image: url("/assets/hero.jpg");
  background-size: 100vw;
  background-position: left top;
  background-repeat: no-repeat;
  position: relative;
}
@media (min-width: 1920px) and (min-aspect-ratio: 21/9) {
  .hero {
    padding-top: 15vw;
  }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .nav-tabs, .page-title, .h6 {
  font-stretch: 44%;
}

h1, .h1 {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

h2, .h2 {
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: bold;
  margin: 0.25rem 0 1rem 0;
  margin-bottom: 2rem;
}
@media (min-width: 768px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}
@media (min-width: 768px) {
  h2, .h2 {
    line-height: 2rem;
  }
}
@media (min-width: 768px) {
  h2, .h2 {
    font-weight: bold;
  }
}
@media (min-width: 768px) {
  h2, .h2 {
    margin: 0.25rem 0 2rem 0;
  }
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.25rem;
  }
}
@media (min-width: 1200px) {
  h2, .h2 {
    line-height: 2.5rem;
  }
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-weight: bold;
  }
}
@media (min-width: 1200px) {
  h2, .h2 {
    margin: 0.5rem 0 2rem 0;
  }
}
h2.light, .h2.light {
  font-weight: 300;
}

h3, .h3 {
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: bold;
  margin: 2rem 0 1rem 0;
}
@media (min-width: 768px) {
  h3, .h3 {
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) {
  h3, .h3 {
    line-height: 1.5rem;
  }
}
@media (min-width: 768px) {
  h3, .h3 {
    font-weight: bold;
  }
}
@media (min-width: 768px) {
  h3, .h3 {
    margin: 2rem 0 1rem 0;
  }
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}
@media (min-width: 1200px) {
  h3, .h3 {
    line-height: 1.75rem;
  }
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-weight: bold;
  }
}
@media (min-width: 1200px) {
  h3, .h3 {
    margin: 4rem 0 2rem 0;
  }
}

h4, .h4 {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: bold;
  margin: 2rem 0 0.5rem 0;
}
@media (min-width: 768px) {
  h4, .h4 {
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  h4, .h4 {
    line-height: 1.125rem;
  }
}
@media (min-width: 768px) {
  h4, .h4 {
    font-weight: bold;
  }
}
@media (min-width: 768px) {
  h4, .h4 {
    margin: 2rem 0 0.5rem 0;
  }
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.125rem;
  }
}
@media (min-width: 1200px) {
  h4, .h4 {
    line-height: 1.375rem;
  }
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-weight: bold;
  }
}
@media (min-width: 1200px) {
  h4, .h4 {
    margin: 2rem 0 1rem 0;
  }
}

h5, .h5, .nav-tabs, .page-title {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: bold;
  margin: 0.5rem 0 0.5rem 0;
}
@media (min-width: 768px) {
  h5, .h5, .nav-tabs, .page-title {
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) {
  h5, .h5, .nav-tabs, .page-title {
    line-height: 1rem;
  }
}
@media (min-width: 768px) {
  h5, .h5, .nav-tabs, .page-title {
    font-weight: bold;
  }
}
@media (min-width: 768px) {
  h5, .h5, .nav-tabs, .page-title {
    margin: 0.5rem 0 0.5rem 0;
  }
}
@media (min-width: 1200px) {
  h5, .h5, .nav-tabs, .page-title {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  h5, .h5, .nav-tabs, .page-title {
    line-height: 1.125rem;
  }
}
@media (min-width: 1200px) {
  h5, .h5, .nav-tabs, .page-title {
    font-weight: bold;
  }
}
@media (min-width: 1200px) {
  h5, .h5, .nav-tabs, .page-title {
    margin: 1rem 0 0.5rem 0;
  }
}

h6, .h6 {
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: bold;
  margin: 0.5rem 0 0.25rem 0;
}
@media (min-width: 768px) {
  h6, .h6 {
    font-size: 0.75rem;
  }
}
@media (min-width: 768px) {
  h6, .h6 {
    line-height: 0.875rem;
  }
}
@media (min-width: 768px) {
  h6, .h6 {
    font-weight: bold;
  }
}
@media (min-width: 768px) {
  h6, .h6 {
    margin: 0.5rem 0 0.25rem 0;
  }
}
@media (min-width: 1200px) {
  h6, .h6 {
    font-size: 0.875rem;
  }
}
@media (min-width: 1200px) {
  h6, .h6 {
    line-height: 1rem;
  }
}
@media (min-width: 1200px) {
  h6, .h6 {
    font-weight: bold;
  }
}
@media (min-width: 1200px) {
  h6, .h6 {
    margin: 0.5rem 0 0.25rem 0;
  }
}

.wood, .page-title {
  color: #888067;
}

.title-line-before:before {
  margin-bottom: 0.75rem;
  content: "";
  display: block;
  width: 4.5rem;
  border-bottom: 2px solid #545b68;
}
@media (min-width: 1200px) {
  .title-line-before:before {
    width: 5rem;
  }
}

.title-line-after:after, .page-title:after {
  margin-top: 0.5rem;
  content: "";
  display: block;
  width: 4.5rem;
  border-bottom: 2px solid #888067;
}
@media (min-width: 1200px) {
  .title-line-after:after, .page-title:after {
    width: 5rem;
  }
}

.page-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media (min-width: 1200px) {
  .page-title {
    margin-top: 2rem;
  }
}

.text-01, p {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 300;
  margin: 0 0 1rem 0;
}
@media (min-width: 768px) {
  .text-01, p {
    font-size: 1rem;
  }
}
@media (min-width: 768px) {
  .text-01, p {
    line-height: 1.25rem;
  }
}
@media (min-width: 768px) {
  .text-01, p {
    font-weight: 300;
  }
}
@media (min-width: 768px) {
  .text-01, p {
    margin: 0 0 1rem 0;
  }
}
@media (min-width: 1200px) {
  .text-01, p {
    font-size: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .text-01, p {
    line-height: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .text-01, p {
    font-weight: 300;
  }
}
@media (min-width: 1200px) {
  .text-01, p {
    margin: 0 0 1rem 0;
  }
}

.text-02, body {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 300;
  margin: 0 0 1rem 0;
}
@media (min-width: 768px) {
  .text-02, body {
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) {
  .text-02, body {
    line-height: 1.125rem;
  }
}
@media (min-width: 768px) {
  .text-02, body {
    font-weight: 300;
  }
}
@media (min-width: 768px) {
  .text-02, body {
    margin: 0 0 1rem 0;
  }
}
@media (min-width: 1200px) {
  .text-02, body {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .text-02, body {
    line-height: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .text-02, body {
    font-weight: 300;
  }
}
@media (min-width: 1200px) {
  .text-02, body {
    margin: 0 0 1rem 0;
  }
}

.text-03, .badge, table th {
  font-size: 0.75rem;
  line-height: 0.875rem;
  font-weight: 300;
  margin: 0;
}
@media (min-width: 768px) {
  .text-03, .badge, table th {
    font-size: 0.75rem;
  }
}
@media (min-width: 768px) {
  .text-03, .badge, table th {
    line-height: 0.875rem;
  }
}
@media (min-width: 768px) {
  .text-03, .badge, table th {
    font-weight: 300;
  }
}
@media (min-width: 768px) {
  .text-03, .badge, table th {
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .text-03, .badge, table th {
    font-size: 0.875rem;
  }
}
@media (min-width: 1200px) {
  .text-03, .badge, table th {
    line-height: 1.125rem;
  }
}
@media (min-width: 1200px) {
  .text-03, .badge, table th {
    font-weight: 300;
  }
}
@media (min-width: 1200px) {
  .text-03, .badge, table th {
    margin: 0;
  }
}

small, .small, .text-04 {
  font-size: 0.625rem;
  line-height: 0.75rem;
  font-weight: 300;
  margin: 0;
  color: #545b68;
}
@media (min-width: 768px) {
  small, .small, .text-04 {
    font-size: 0.625rem;
  }
}
@media (min-width: 768px) {
  small, .small, .text-04 {
    line-height: 0.75rem;
  }
}
@media (min-width: 768px) {
  small, .small, .text-04 {
    font-weight: 300;
  }
}
@media (min-width: 768px) {
  small, .small, .text-04 {
    margin: 0;
  }
}
@media (min-width: 1200px) {
  small, .small, .text-04 {
    font-size: 0.75rem;
  }
}
@media (min-width: 1200px) {
  small, .small, .text-04 {
    line-height: 0.875rem;
  }
}
@media (min-width: 1200px) {
  small, .small, .text-04 {
    font-weight: 300;
  }
}
@media (min-width: 1200px) {
  small, .small, .text-04 {
    margin: 0;
  }
}

code {
  font-family: monospace;
  color: #891023;
  background: #eff2f4;
  padding: 0.2rem;
}

em {
  font-style: italic;
}

a, .nav-tabs button {
  text-decoration: none;
  color: #cc0033;
  transition: all 0.25s ease-in-out;
}
a:hover, .nav-tabs button:hover, a:active, .nav-tabs button:active, a:focus, .nav-tabs button:focus, a:active:focus {
  background: #e0e5e8;
}
a:visited, .nav-tabs button:visited {
  color: #aa112d;
}
a img, .nav-tabs button img {
  width: 2.5rem;
  margin-right: -1rem;
}
a .library-bekb-icon-font, .nav-tabs button .library-bekb-icon-font {
  font-size: 0.625rem;
  line-height: 0.75rem;
  font-weight: 300;
  margin: 0;
}
@media (min-width: 768px) {
  a .library-bekb-icon-font, .nav-tabs button .library-bekb-icon-font {
    font-size: 0.625rem;
  }
}
@media (min-width: 768px) {
  a .library-bekb-icon-font, .nav-tabs button .library-bekb-icon-font {
    line-height: 0.75rem;
  }
}
@media (min-width: 768px) {
  a .library-bekb-icon-font, .nav-tabs button .library-bekb-icon-font {
    font-weight: 300;
  }
}
@media (min-width: 768px) {
  a .library-bekb-icon-font, .nav-tabs button .library-bekb-icon-font {
    margin: 0;
  }
}
@media (min-width: 1200px) {
  a .library-bekb-icon-font, .nav-tabs button .library-bekb-icon-font {
    font-size: 0.75rem;
  }
}
@media (min-width: 1200px) {
  a .library-bekb-icon-font, .nav-tabs button .library-bekb-icon-font {
    line-height: 0.875rem;
  }
}
@media (min-width: 1200px) {
  a .library-bekb-icon-font, .nav-tabs button .library-bekb-icon-font {
    font-weight: 300;
  }
}
@media (min-width: 1200px) {
  a .library-bekb-icon-font, .nav-tabs button .library-bekb-icon-font {
    margin: 0;
  }
}
a.link-icon-text .library-bekb-icon-font, .nav-tabs button.link-icon-text .library-bekb-icon-font {
  margin-right: 0.5rem;
}

.btn {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 300;
  margin: 0 0 1rem 0;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 0;
  padding: 0.75rem;
  transition: all 0.25s ease-in-out;
}
@media (min-width: 768px) {
  .btn {
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) {
  .btn {
    line-height: 1.125rem;
  }
}
@media (min-width: 768px) {
  .btn {
    font-weight: 300;
  }
}
@media (min-width: 768px) {
  .btn {
    margin: 0 0 1rem 0;
  }
}
@media (min-width: 1200px) {
  .btn {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .btn {
    line-height: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .btn {
    font-weight: 300;
  }
}
@media (min-width: 1200px) {
  .btn {
    margin: 0 0 1rem 0;
  }
}
.btn.disabled, .btn:disabled {
  color: #545b68;
  background-color: #e0e5e8;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn-primary {
  background: #e0e5e8;
  color: #cc0033;
}
.btn-primary:not(:disabled):not(.disabled):hover, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):focus {
  color: #891023;
  background-color: #d3d8dd;
  box-shadow: none;
}

.btn-call-to-action {
  padding-left: 2rem;
  padding-right: 2rem;
  background: #cc0033;
  color: #ffffff;
}
.btn-call-to-action:not(:disabled):not(.disabled):hover, .btn-call-to-action:not(:disabled):not(.disabled):active, .btn-call-to-action:not(:disabled):not(.disabled).active, .btn-call-to-action:not(:disabled):not(.disabled).active:focus, .btn-call-to-action:not(:disabled):not(.disabled):focus {
  color: #ffffff;
  background-color: #891023;
  box-shadow: none;
}

.btn-link {
  background: #ffffff;
  color: #cc0033;
}
.btn-link:hover, .btn-link:active, .btn-link:focus, .btn-link:active:focus {
  background: #e0e5e8;
}
.btn-link.link-text-icon .library-bekb-icon-font {
  margin-left: 0.5rem;
}

.btn-up {
  white-space: nowrap;
  background: #545b68;
  height: 3rem;
  width: 3rem;
  transform: translateY(3rem);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  pointer-events: auto;
}
.btn-up span {
  color: #ffffff;
}

.btn-clear-search {
  font-size: 0.625rem;
  line-height: 0.75rem;
  font-weight: 300;
  margin: 0;
  background: #ffffff;
  color: #545b68;
  padding: 0;
}
@media (min-width: 768px) {
  .btn-clear-search {
    font-size: 0.625rem;
  }
}
@media (min-width: 768px) {
  .btn-clear-search {
    line-height: 0.75rem;
  }
}
@media (min-width: 768px) {
  .btn-clear-search {
    font-weight: 300;
  }
}
@media (min-width: 768px) {
  .btn-clear-search {
    margin: 0;
  }
}
@media (min-width: 1200px) {
  .btn-clear-search {
    font-size: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .btn-clear-search {
    line-height: 0.875rem;
  }
}
@media (min-width: 1200px) {
  .btn-clear-search {
    font-weight: 300;
  }
}
@media (min-width: 1200px) {
  .btn-clear-search {
    margin: 0;
  }
}
.btn-clear-search:hover, .btn-clear-search:active, .btn-clear-search:focus, .btn-clear-search:active:focus {
  color: #000000;
}

.slide-in {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.btn-icon-text .library-bekb-icon-font {
  margin-right: 0.5rem;
}

input, button, textarea {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 300;
  margin: 0 0 1rem 0;
}
@media (min-width: 768px) {
  input, button, textarea {
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) {
  input, button, textarea {
    line-height: 1.125rem;
  }
}
@media (min-width: 768px) {
  input, button, textarea {
    font-weight: 300;
  }
}
@media (min-width: 768px) {
  input, button, textarea {
    margin: 0 0 1rem 0;
  }
}
@media (min-width: 1200px) {
  input, button, textarea {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  input, button, textarea {
    line-height: 1.25rem;
  }
}
@media (min-width: 1200px) {
  input, button, textarea {
    font-weight: 300;
  }
}
@media (min-width: 1200px) {
  input, button, textarea {
    margin: 0 0 1rem 0;
  }
}

.form {
  display: grid;
  grid-template-rows: auto;
  justify-items: stretch;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-columns: 1fr;
  grid-column-gap: 7px;
}
@media (min-width: 768px) {
  .form {
    grid-column-gap: 8px;
  }
}
@media (min-width: 1200px) {
  .form {
    grid-column-gap: 12px;
  }
}

.form-control {
  position: relative;
  margin: 0.5rem 0;
}

.input {
  font-size: 1rem;
  position: relative;
  width: 100%;
  z-index: 20;
  color: #000000;
  border-color: #e0e5e8;
  border-style: solid;
  border-width: 0 0 0.0625em 0.0625em;
  padding: 0.625em 0 0.625em 0.625em;
  transition: all 0.25s ease-in-out;
}
.input:focus {
  border-color: #000000;
}
.input::-ms-clear {
  display: none;
}

.input:focus + label.floating, .input.has-value + label.floating {
  font-size: 0.75rem;
  top: -0.875rem;
  transform: none;
  pointer-events: none;
}

label {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 300;
  margin: 0 0 1rem 0;
  color: #545b68;
  display: inline-block;
}
@media (min-width: 768px) {
  label {
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) {
  label {
    line-height: 1.125rem;
  }
}
@media (min-width: 768px) {
  label {
    font-weight: 300;
  }
}
@media (min-width: 768px) {
  label {
    margin: 0 0 1rem 0;
  }
}
@media (min-width: 1200px) {
  label {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  label {
    line-height: 1.25rem;
  }
}
@media (min-width: 1200px) {
  label {
    font-weight: 300;
  }
}
@media (min-width: 1200px) {
  label {
    margin: 0 0 1rem 0;
  }
}
.form-control label {
  margin-bottom: 0.25rem;
}
label.floating {
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 20;
  transform: translateY(-50%);
  color: #545b68;
  transition: all 0.25s ease-in-out;
  pointer-events: none;
}
label.radio-wrapper {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  padding: 0.125rem 0 0 1.75rem;
  margin-bottom: 1rem;
  pointer-events: auto;
  cursor: pointer;
}
label.radio-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
label.radio-wrapper input:checked ~ .styled-radio::after {
  background: #000000;
}
label.radio-wrapper .styled-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #c4ccd3;
  border-radius: 50%;
}
label.radio-wrapper .styled-radio::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: white;
}

.inline-submit {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 30;
  height: 100%;
  color: #cc0033;
  background: transparent;
  border: none;
}

.select-wrapper {
  position: relative;
}
.select-wrapper::before {
  content: "\e908";
  font-family: "bekb-icon-font", sans-serif;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #545b68;
  font-size: 0.75rem;
}

select {
  position: relative;
  width: 100%;
  padding: 0.625rem;
  background: transparent;
  border-color: #e0e5e8;
  border-style: solid;
  border-width: 0.0625rem;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  appearance: none;
  color: #545b68;
  font-weight: bold;
}
select::-ms-expand {
  display: none;
}
select:focus {
  border-color: #000000;
}
select option {
  color: #000000;
  font-weight: bold;
}
select option:disabled {
  color: #c4ccd3;
}

.search-box {
  margin-top: 0.5rem;
  white-space: nowrap;
  align-self: end;
  position: relative;
  display: flex;
}
.search-box .input {
  margin-bottom: 0;
}
.search-box .input.has-value {
  padding-right: 6rem;
}
.modal-body .search-box .input.has-value {
  padding-right: 4rem;
}

.search-box .search-box-action {
  position: absolute;
  right: 0.5rem;
  z-index: 30;
  height: 100%;
  display: flex;
  align-items: center;
}

.search-button {
  align-self: center;
  align-items: center;
  justify-self: right;
}
.search-button .btn.btn-link {
  padding-right: 0;
  font-weight: bold;
}

.search-icon {
  color: #cc0033;
  cursor: pointer;
}

.card {
  margin-bottom: 2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.35);
  background: #ffffff;
}
.card .card-header, .card .card-header-panel {
  padding-top: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media (min-width: 768px) {
  .card .card-header, .card .card-header-panel {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.card .card-header-panel {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background: #c4ccd3;
  min-height: 3.625rem;
}
@media (min-width: 768px) {
  .card .card-header-panel {
    min-height: 3.75rem;
  }
}
@media (min-width: 1200px) {
  .card .card-header-panel {
    min-height: 4.125rem;
  }
}
.card .card-body-plaintext, .card .error-or-loading-wrapper, .card .card-body-no-data, .card .card-body-subtitle {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .card .card-body-plaintext, .card .error-or-loading-wrapper, .card .card-body-no-data, .card .card-body-subtitle {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .card .card-body-plaintext, .card .error-or-loading-wrapper, .card .card-body-no-data, .card .card-body-subtitle {
    padding-top: 1rem;
  }
}
.card .card-body-no-data {
  color: #545b68;
}
.card .card-body-subtitle h5 {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .card .card-body-subtitle h5 {
    margin-top: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .card .card-body-subtitle {
    padding-top: 0.75rem;
  }
}
.card .card-body-kpi {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #e0e5e8;
}
@media (min-width: 768px) {
  .card .card-body-kpi {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .card .card-body-kpi {
    padding-top: 1rem;
  }
}
.card .card-body-kpi h2 {
  margin: 0;
  margin-top: 0.25rem;
  line-height: 1;
  flex: 0 0 100%;
}
.card .card-body-table {
  margin-top: 0.25rem;
}
@media (min-width: 1200px) {
  .card .card-body-table {
    margin-top: 0.5rem;
  }
}
.card .card-body-list li {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: block;
  list-style-type: none;
  border-bottom: 1px solid #e0e5e8;
}
@media (min-width: 768px) {
  .card .card-body-list li {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.card .card-header-panel + .card-body-subtitle > h5 {
  margin: 0;
}
.card .card-footer-actions,
.card .card-footer {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .card .card-footer-actions,
  .card .card-footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.card .card-footer-actions > div, .card .card-footer-actions > button, .card .card-footer-actions > a {
  flex: 1 1 100%;
}
@media (min-width: 768px) {
  .card .card-footer-actions > div, .card .card-footer-actions > button, .card .card-footer-actions > a {
    flex: 1 0 auto;
  }
}
.card .card-footer-expand .btn-primary {
  background-color: #eff2f4;
  width: 100%;
}
.card .card-footer-table-link,
.card .card-footer-link {
  padding-bottom: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media (min-width: 768px) {
  .card .card-footer-table-link,
  .card .card-footer-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.card .card-footer-table-link {
  padding-top: 1.5rem;
}
@media (min-width: 768px) {
  .card .card-footer-table-link {
    padding-top: 2rem;
  }
}
.card .two-columns {
  display: grid;
  grid-template-rows: auto;
  justify-items: stretch;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-columns: 1fr;
  grid-column-gap: 2rem;
}
@media (min-width: 768px) {
  .card .two-columns {
    grid-column-gap: 2rem;
  }
}
@media (min-width: 1200px) {
  .card .two-columns {
    grid-column-gap: 2rem;
  }
}
.card .two-columns h5 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
@media (min-width: 768px) {
  .card .two-columns h5 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.card h4:first-child {
  margin: 0;
}
.card h5 {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .card h5 {
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .card h5 {
    margin-bottom: 0;
  }
}

table {
  width: 100%;
}
table:not(:last-child) {
  border-bottom: 1px solid #e0e5e8;
}
table.table-border-top {
  border-top: 1px solid #e0e5e8;
}
table.table-border-bottom {
  border-bottom: 1px solid #e0e5e8;
}
table th, table td {
  border-bottom: 1px solid #e0e5e8;
  padding: 0 0.5rem;
}
@media (min-width: 768px) {
  table th, table td {
    padding: 0 1rem;
  }
}
table th {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  text-align: left;
  color: #545b68;
}
table th.sticky-top {
  padding: 0;
}
table th.sticky-top > span {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: #ffffff;
  display: inline-block;
  width: 100%;
}
@media (min-width: 768px) {
  table th.sticky-top > span {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (min-width: 1200px) {
  table th.sticky-top > span {
    padding-top: 0.5rem;
  }
}
@media (min-width: 1200px) {
  table th {
    padding-top: 0.5rem;
  }
}
table .trades-off-book-tr > td {
  vertical-align: bottom;
}
table .align-left {
  text-align: left;
}
table .align-right {
  text-align: right;
}
table .align-center {
  text-align: center;
}

header {
  display: grid;
  grid-template-rows: auto;
  justify-items: stretch;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-columns: 1fr;
  grid-column-gap: 7px;
  align-items: start;
  border-top: 0.5rem solid #cc0033;
}
@media (min-width: 768px) {
  header {
    grid-column-gap: 8px;
  }
}
@media (min-width: 1200px) {
  header {
    grid-column-gap: 12px;
  }
}
header .main-logo-mb a:hover, header .main-logo-mb .nav-tabs button:hover, .nav-tabs header .main-logo-mb button:hover,
header .main-logo-mb a:active,
header .main-logo-mb .nav-tabs button:active,
.nav-tabs header .main-logo-mb button:active,
header .main-logo-mb a:focus,
header .main-logo-mb .nav-tabs button:focus,
.nav-tabs header .main-logo-mb button:focus,
header .main-logo-mb a:active:focus, header .main-logo-dt a:hover, header .main-logo-dt .nav-tabs button:hover, .nav-tabs header .main-logo-dt button:hover,
header .main-logo-dt a:active,
header .main-logo-dt .nav-tabs button:active,
.nav-tabs header .main-logo-dt button:active,
header .main-logo-dt a:focus,
header .main-logo-dt .nav-tabs button:focus,
.nav-tabs header .main-logo-dt button:focus,
header .main-logo-dt a:active:focus {
  background: transparent;
}
header .main-logo-mb {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: block;
}
header .main-logo-mb img {
  width: auto;
  height: 1.5rem;
}
header .main-logo-dt,
header .search-box {
  display: none;
}
header .main-logo-dt {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
header .main-logo-dt img {
  width: auto;
  height: 2.5rem;
}
@media (min-width: 1200px) {
  header .main-logo-dt img {
    height: 3rem;
  }
}
header .search-button {
  display: flex;
}
@media (min-width: 768px) {
  header .main-logo-mb,
  header .search-button {
    display: none;
  }
  header .main-logo-dt {
    display: block;
  }
  header .search-box {
    display: flex;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-primary-container {
  position: relative;
}
.nav-primary-container::after {
  display: block;
  position: absolute;
  content: " ";
  bottom: -0.5rem;
  height: 0.5rem;
  width: 100%;
  background: #e0e5e8;
}
.nav-primary-container .nav-primary ul {
  display: grid;
  grid-template-rows: auto;
  justify-items: stretch;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-columns: 1fr;
  grid-column-gap: 7px;
  justify-items: start;
  align-items: stretch;
  grid-column-gap: 0;
}
@media (min-width: 768px) {
  .nav-primary-container .nav-primary ul {
    grid-column-gap: 8px;
  }
}
@media (min-width: 1200px) {
  .nav-primary-container .nav-primary ul {
    grid-column-gap: 12px;
  }
}
.nav-primary-container .nav-primary ul li {
  width: 100%;
}
.nav-primary-container .nav-primary ul li a, .nav-primary-container .nav-primary ul li .nav-tabs button, .nav-tabs .nav-primary-container .nav-primary ul li button {
  display: flex;
  color: #545b68;
  font-weight: bold;
  border-left: 2px solid #c4ccd3;
}
.nav-primary-container .nav-primary ul li a:hover, .nav-primary-container .nav-primary ul li .nav-tabs button:hover, .nav-tabs .nav-primary-container .nav-primary ul li button:hover,
.nav-primary-container .nav-primary ul li a:active,
.nav-primary-container .nav-primary ul li .nav-tabs button:active,
.nav-tabs .nav-primary-container .nav-primary ul li button:active,
.nav-primary-container .nav-primary ul li a:focus,
.nav-primary-container .nav-primary ul li .nav-tabs button:focus,
.nav-tabs .nav-primary-container .nav-primary ul li button:focus,
.nav-primary-container .nav-primary ul li a:active:focus {
  background: #e0e5e8;
  color: #000000;
}
.nav-primary-container .nav-primary ul li:last-child a, .nav-primary-container .nav-primary ul li:last-child .nav-tabs button, .nav-tabs .nav-primary-container .nav-primary ul li:last-child button {
  border-right: 2px solid #c4ccd3;
}
.nav-primary-container .nav-primary ul .active a, .nav-primary-container .nav-primary ul .active .nav-tabs button, .nav-tabs .nav-primary-container .nav-primary ul .active button {
  background: #e0e5e8;
  color: #000000;
}

.nav-secondary-container {
  background: #e0e5e8;
}
.nav-secondary-container .nav-secondary ul {
  display: flex;
  justify-items: start;
  align-items: stretch;
}
.nav-secondary-container .nav-secondary ul li a, .nav-secondary-container .nav-secondary ul li .nav-tabs button, .nav-tabs .nav-secondary-container .nav-secondary ul li button {
  display: flex;
  color: #545b68;
  border-left: 2px solid #c4ccd3;
}
.nav-secondary-container .nav-secondary ul li a:hover, .nav-secondary-container .nav-secondary ul li .nav-tabs button:hover, .nav-tabs .nav-secondary-container .nav-secondary ul li button:hover,
.nav-secondary-container .nav-secondary ul li a:active,
.nav-secondary-container .nav-secondary ul li .nav-tabs button:active,
.nav-tabs .nav-secondary-container .nav-secondary ul li button:active,
.nav-secondary-container .nav-secondary ul li a:focus,
.nav-secondary-container .nav-secondary ul li .nav-tabs button:focus,
.nav-tabs .nav-secondary-container .nav-secondary ul li button:focus,
.nav-secondary-container .nav-secondary ul li a:active:focus {
  background: #ffffff;
  color: #000000;
}
.nav-secondary-container .nav-secondary ul li:last-child a, .nav-secondary-container .nav-secondary ul li:last-child .nav-tabs button, .nav-tabs .nav-secondary-container .nav-secondary ul li:last-child button {
  border-right: 2px solid #c4ccd3;
}

.nav-stretch-items .nav-item {
  flex-grow: 1;
}

.nav-tabs {
  margin: 0;
  border-bottom: 8px solid #c4ccd3;
}
.nav-tabs .nav-item:not(:last-child) {
  margin-right: 0.25rem;
}
.nav-tabs .nav-link {
  padding: 0.5rem;
  cursor: pointer;
  display: block;
  text-decoration: none;
  color: #545b68;
  background: #e0e5e8;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-link:hover {
  background: #c4ccd3;
  color: #000000;
}

footer {
  background: #545b68;
  color: #ffffff;
  line-height: 1.3;
}
footer h5 {
  border-bottom: 1px solid #ffffff;
  display: none;
}
@media (min-width: 768px) {
  footer h5 {
    display: block;
  }
}
footer a, footer .nav-tabs button, .nav-tabs footer button,
footer a:hover,
footer a:active,
footer a:focus,
footer a:visited,
footer a:active:focus {
  color: #ffffff;
  text-decoration: underline;
  background: transparent;
}
footer .footer-content {
  display: grid;
  grid-template-rows: auto;
  justify-items: stretch;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-columns: 1fr;
  grid-column-gap: 7px;
  grid-template-areas: "address" "about" "contact" "legal";
}
@media (min-width: 768px) {
  footer .footer-content {
    grid-column-gap: 8px;
  }
}
@media (min-width: 1200px) {
  footer .footer-content {
    grid-column-gap: 12px;
  }
}
@media (min-width: 768px) {
  footer .footer-content {
    display: grid;
    grid-template-rows: auto;
    justify-items: stretch;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-columns: 1fr;
    grid-column-gap: 7px;
    grid-template-areas: "address about contact contact" "legal legal legal legal";
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  footer .footer-content {
    grid-column-gap: 8px;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  footer .footer-content {
    grid-column-gap: 12px;
  }
}
footer .footer-content > div:not(:last-of-type) {
  border-bottom: 1px solid #ffffff;
}
@media (min-width: 768px) {
  footer .footer-content > div:not(:last-of-type) {
    border-bottom: none;
  }
}
footer .footer-address {
  grid-area: address;
  white-space: pre-line;
}
footer .footer-about {
  grid-area: about;
}
footer .footer-contact {
  grid-area: contact;
}
footer .footer-contact a, footer .footer-contact .nav-tabs button, .nav-tabs footer .footer-contact button {
  white-space: nowrap;
}
footer .footer-legal {
  grid-area: legal;
  display: grid;
  grid-template-rows: auto;
  justify-items: stretch;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-columns: 1fr;
  grid-column-gap: 0;
}
@media (min-width: 768px) {
  footer .footer-legal {
    grid-column-gap: 0;
  }
}
@media (min-width: 1200px) {
  footer .footer-legal {
    grid-column-gap: 0;
  }
}
footer .footer-legal div {
  white-space: nowrap;
}
footer .footer-legal div.copyright {
  grid-row: 4;
}
footer .footer-legal div.copyright::before {
  content: "";
  display: block;
}
@media (min-width: 768px) {
  footer .footer-legal {
    display: grid;
    grid-template-rows: auto;
    justify-items: stretch;
    grid-template-columns: repeat(4, min-content);
    grid-auto-columns: 1fr;
    grid-column-gap: 0;
    grid-auto-rows: auto;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  footer .footer-legal {
    grid-column-gap: 0;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  footer .footer-legal {
    grid-column-gap: 0;
  }
}
@media (min-width: 768px) {
  footer .footer-legal div.copyright {
    grid-row: 1;
  }
  footer .footer-legal div.copyright::before {
    display: none;
  }
  footer .footer-legal div:not(:first-of-type)::before {
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 300;
    margin: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    content: "|";
    position: relative;
    top: -2px;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  footer .footer-legal div:not(:first-of-type)::before {
    font-size: 0.75rem;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  footer .footer-legal div:not(:first-of-type)::before {
    line-height: 0.875rem;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  footer .footer-legal div:not(:first-of-type)::before {
    font-weight: 300;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  footer .footer-legal div:not(:first-of-type)::before {
    margin: 0;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  footer .footer-legal div:not(:first-of-type)::before {
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  footer .footer-legal div:not(:first-of-type)::before {
    line-height: 1.125rem;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  footer .footer-legal div:not(:first-of-type)::before {
    font-weight: 300;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  footer .footer-legal div:not(:first-of-type)::before {
    margin: 0;
  }
}

.badge {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: inline-block;
  border-radius: 1rem;
  border: 1px solid #c4ccd3;
  background: none;
  cursor: default;
  color: #545b68;
}
a.badge:not(:disabled):not(.disabled), .nav-tabs button.badge:not(:disabled):not(.disabled) {
  color: #cc0033;
}

button.badge, a.badge {
  transition: all 0.25s ease-in-out;
}
button.badge.disabled, a.badge.disabled {
  color: #545b68;
  pointer-events: none;
}
button.badge:not(:disabled):not(.disabled), a.badge:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button.badge.badge-filter, a.badge.badge-filter {
  font-weight: bold;
  border: none;
  background: #e0e5e8;
  color: #545b68;
}
button.badge.badge-filter:active, button.badge.badge-filter.selected, a.badge.badge-filter:active, a.badge.badge-filter.selected {
  background: #545b68;
  color: white;
}
button.badge.badge-filter.disabled, button.badge.badge-filter:disabled, a.badge.badge-filter.disabled, a.badge.badge-filter:disabled {
  color: #939ba8;
  background-color: #eff2f4;
}
button.badge:not(:disabled):not(.disabled):focus, a.badge:not(:disabled):not(.disabled):focus {
  background: #e0e5e8;
}
button.badge.badge-filter:not(:disabled):not(.disabled):focus, a.badge.badge-filter:not(:disabled):not(.disabled):focus {
  background: #c4ccd3;
}
button.badge.badge-filter:not(:disabled):not(.disabled):focus.selected, a.badge.badge-filter:not(:disabled):not(.disabled):focus.selected {
  background: #000000;
}
@media (min-width: 1200px) {
  button.badge:active, button.badge:not(:disabled):not(.disabled):hover, a.badge:active, a.badge:not(:disabled):not(.disabled):hover {
    background: #e0e5e8;
  }
  button.badge.badge-filter:not(:disabled):not(.disabled):hover, a.badge.badge-filter:not(:disabled):not(.disabled):hover {
    background: #c4ccd3;
  }
  button.badge.badge-filter:not(:disabled):not(.disabled):hover.selected, a.badge.badge-filter:not(:disabled):not(.disabled):hover.selected {
    background: #000000;
  }
}

.badge.badge-hint {
  color: #000000;
  background: #d6c69a;
  border: 1px solid #d6c69a;
}
.badge .small {
  font-weight: bold;
  color: inherit;
}

.text-danger {
  color: #cc0033;
}

.text-success {
  color: #608000;
}

.error-or-loading-wrapper .error-icon-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #eff2f4;
  color: #545b68;
  display: flex;
  align-items: center;
}
.error-or-loading-wrapper .error-icon-text img {
  height: 2rem;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.dot {
  display: inline-block;
  background-color: #545b68;
  border-radius: 50%;
  height: 0.25rem;
  width: 0.25rem;
  margin: 0 0.075rem;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
@media (min-width: 1200px) {
  .dot {
    height: 0.3125rem;
    width: 0.3125rem;
    margin: 0 0.09375rem;
  }
}
.small .dot {
  height: 0.15rem;
  width: 0.15rem;
  margin: 0 0.05rem;
}
@media (min-width: 1200px) {
  .small .dot {
    height: 0.1875rem;
    width: 0.1875rem;
    margin: 0 0.0625rem;
  }
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

otcx-wysiwyg-content ul {
  margin-bottom: 2rem;
  padding-left: 1rem;
  list-style: disc outside;
}
otcx-wysiwyg-content ol {
  margin-bottom: 2rem;
  padding-left: 1rem;
  list-style: decimal outside;
}